import React from "react"
import { Link } from "gatsby"

import { FaMap, FaClipboardList, FaSearch, FaUser } from "react-icons/fa"
import { FiLayers } from "react-icons/fi"
import { BsFillFileEarmarkRichtextFill } from "react-icons/bs"
import { MdOutlineGpsFixed } from "react-icons/md"

import { Layout, Seo, classes } from "../../layout"
import {
  AvailableAsWebApp,
  FindOutMoreButton,
  IPhone,
  img,
} from "../../components"

export default function IndexPage(props) {
  const { heading, iphone } = classes
  const section = "p-6 md:px-16 max-w-5xl mx-auto"
  const sub_heading =
    "flex justify-left items-center mb-4 text-base sm:text-xl font-bold"
  const paragraph = "my-6"

  return (
    <Layout path={props.path}>
      <Seo title="The Trailguide App" />

      <div className={section + " mt-24 flex text-lg leading-7"}>
        <div>
          <h1 className={heading}>The Trailguide App</h1>
          <p className="py-2">
            Trailguide is an app to share, find, and navigate outdoor activities
            crowd-sourced by outdoor enthusiasts.
          </p>
          <p className="py-2">
            The app offers maps, height profiles, navigation, tour descriptions,
            images, videos, reviews, and condition reports.
          </p>
          <p className="py-2">
            The{" "}
            <a
              href="https://en.wikipedia.org/wiki/Progressive_web_app"
              target="wik"
            >
              web app
            </a>{" "}
            is accessed through your web browser by going to{" "}
            <a href="https://trailguide.net" target="trailguide">
              trailguide.net
            </a>{" "}
            and is <Link to="/howto/install">installed</Link> by adding it to
            your home screen.
          </p>
          <p className="py-2">
            Read more about the <Link to="#map">maps</Link>,{" "}
            <Link to="#layers">layers</Link>, <Link to="#list">list</Link>,{" "}
            <Link to="#page">content</Link>,{" "}
            <Link to="#navigation">navigation</Link>,{" "}
            <Link to="#search">searching</Link>,{" "}
            <Link to="#search">filtering</Link>, and{" "}
            <Link to="#search">user pages</Link>
          </p>
          <p className="md:flex md:items-start mt-12">
            <Link href="https://trailguide.net" target="trailguide.net">
              <AvailableAsWebApp label="trailguide.net" />
            </Link>
            <FindOutMoreButton
              className="mt-4 md:mt-0 md:ml-4 md:h-14"
              to="/howto/install"
            >
              Install Trailguide
            </FindOutMoreButton>
          </p>
        </div>
        <IPhone
          alt="An app to find and share outdoor activities"
          src={img("/website/trailguide-iphone-world-4.jpeg")}
          className={`hidden sm:block ${iphone}`}
        />
      </div>

      <div id="map" className={section}>
        <div className={sub_heading}>
          <Icon>
            <FaMap className="w-full h-full" />
          </Icon>
          <div>The map</div>
        </div>
        <p className={paragraph}>
          The Trailguide App is focused around the map. Activities are clustered
          in blue "bubbles" of varying sizes and color depth to indicate the
          number of activities in the area. You can zoom and move around the
          map. You can click on the blue bubbles to zoom in to that specific
          area. Click on a track to show information about it at the bottom of
          the screen. Clicking on the bottom information bar will open the full
          trail page.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Trail clustering in Finale Ligure"
              src={img("/website/trailguide-iphone-map-cluster.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              The blue "bubble" with the number 77 indicates that there are 77
              trails to be found in the area of Finale Ligure in Italy.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Clicking on the cluster bubble"
              src={img("/website/trailguide-iphone-map.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Clicking on the "cluster bubble" will zoom into the area of Finale
              Ligure and reveal all the tracks on the map.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Clicking on a track"
              src={img("/website/trailguide-iphone-map-track.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Clicking on a track will show information about the trail,
              including the height profile. Moving your finger on the height
              profile indicates the position on the track.
            </div>
          </div>
        </div>
      </div>

      <div id="navigation" className={section}>
        <div className={sub_heading}>
          <Icon>
            <MdOutlineGpsFixed className="w-full h-full" />
          </Icon>
          <div>Navigation</div>
        </div>
        <p className={paragraph}>
          Use the GPS of your phone to navigate directly using Trailguide.
          <br />
          <Link to="/howto/navigation">
            How do I use Trailguide for navigation?
          </Link>
          <br />
          <Link to="/howto/gps-on-iphone">
            How to get GPS location working for Trailguide on your iPhone
          </Link>
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Navigate using GPS"
              src={img("/website/trailguide-iphone-location.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Show your current position on the map by clicking the Location
              button.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Navigation mode"
              src={img("/website/trailguide-iphone-navigation-switch.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Turn on navigation mode for Trailguide to continuously update the
              position on the map, show a heading arrow, and leave "breadcrumbs"
              in your path.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Toggle navigation mode"
              src={img("/howto/trailguide-iphone-navigation-3.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Toggle navigation mode by clicking the navigation icon for more
              than one second.
            </div>
          </div>
        </div>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Heading-up"
              src={img("/website/trailguide-iphone-heading-up.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Show heading-up instead of north-up by clicking the compass icon
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Breadcrumbs"
              src={img("/website/trailguide-iphone-breadcrumbs.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              While in navigation mode, Trailguide will leave "breadcrumbs" in
              your path so you can see where you came from.
            </div>
          </div>
        </div>
      </div>

      <div id="layers" className={section}>
        <div className={sub_heading}>
          <Icon>
            <FiLayers className="w-full h-full" />
          </Icon>
          <div>Map layers</div>
        </div>
        <p className={paragraph}>
          You can select your base map layer and map overlays by clicking the
          map-selector button at the right edge of the screen. For the base
          layer you can select the standard Trailguide map, Norgeskart, MTBmap,
          Google maps, and Google satellite images.
        </p>

        <div className="sm:flex flex-wrap justify-center mt-12">
          <div className="sm:w-1/3 mb-12 my-4">
            <IPhone
              alt="The map selector"
              src={img("/website/trailguide-iphone-satellite.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-2 text-sm mx-auto max-w-sm italic">
              Clicking the map selector button enables you to select base maps
              and overlays.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12 my-4">
            <IPhone
              alt="MTB Tracks"
              src={img("/website/trailguide-iphone-mtbtracks.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-2 text-sm mx-auto max-w-sm italic">
              Show MTB tracks from OpenStreetMap as an overlay and enable you to
              navigate a dense area of trails.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12 my-4">
            <IPhone
              alt="Heatmap"
              src={img("/website/trailguide-iphone-heatmap.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-2 text-sm mx-auto max-w-sm italic">
              Showing the Strava heatmap you can see where others have been
              riding and enable you to find new hotspots for trail biking.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12 my-4">
            <IPhone
              alt="Cycle routes"
              src={img("/website/trailguide-iphone-cycleroutes.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-2 text-sm mx-auto max-w-sm italic">
              Show cycle routes so you can safely navigate roads in tight city
              areas.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12 my-4">
            <IPhone
              alt="Grayscale"
              src={img("/website/trailguide-iphone-grayscale.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-2 text-sm mx-auto max-w-sm italic">
              Use a grayscale map for better contrast between overlaid content
              and the base map.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12 my-4">
            <IPhone
              alt="Transparent"
              src={img("/website/trailguide-iphone-transparent.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-2 text-sm mx-auto max-w-sm italic">
              To see what is underneath a track (is it a road or a trail), you
              can make the overlay transparent.
            </div>
          </div>
        </div>
      </div>

      <div id="list" className={section}>
        <div className={sub_heading}>
          <Icon>
            <FaClipboardList className="w-full h-full" />
          </Icon>
          <div>The list of content</div>
        </div>
        <p className={paragraph}>
          Browse the content from the current map selection by clicking the icon
          on the left edge of the screen. The list enables you to see activities
          from the current map section, reviews, and condition reports. Clicking
          on an entry in the list will open the activity page.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Browse activities"
              src={img("/website/trailguide-iphone-list-trails.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Browse the activities within the current map section.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Browse activities"
              src={img("/website/trailguide-iphone-list-reviews.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-center text-sm mx-auto max-w-sm italic">
              Check user reviews.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Browse activities"
              src={img("/website/trailguide-iphone-list-conditions.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-center text-sm mx-auto max-w-sm italic">
              Check recent condition reports.
            </div>
          </div>
        </div>
      </div>

      <div id="page" className={section}>
        <div className={sub_heading}>
          <Icon>
            <BsFillFileEarmarkRichtextFill className="w-full h-full" />
          </Icon>
          <div>The content</div>
        </div>
        <p className={paragraph}>
          For trails and tours on Trailguide you will find a description, a map,
          a height profile, images, a video, reviews, and condition reports. You
          will also find information about other nearby trails and detailed data
          such as vertical gain, and track length. You can also download the
          GPS-track if you want to transfer it to your favorite GPS device.
        </p>

        <div className="sm:flex flex-wrap justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="The content page"
              src={img("/website/trailguide-iphone-page-activity.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              The content page for a trail on Trailguide.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="The content page map and height profile"
              src={img("/website/trailguide-iphone-page-map.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Check the height profile of the track.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Trail reviews"
              src={img("/website/trailguide-iphone-page-reviews.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Check the latest reviews of the trail.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Write a trail review"
              src={img("/website/trailguide-iphone-page-writereview.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Write your own trail reviews so others can see what you think.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Write a condition report"
              src={img("/website/trailguide-iphone-page-writereport.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Write a condition report to let other users and the destination
              know.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Indicate on the map"
              src={img("/website/trailguide-iphone-page-show.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Mark the track on the map so it is easier to follow.
            </div>
          </div>
        </div>
      </div>

      <div id="search" className={section}>
        <div className={sub_heading}>
          <Icon>
            <FaSearch className="w-full h-full" />
          </Icon>
          <div>Searching and filtering</div>
        </div>
        <p className={paragraph}>
          Use the search field to search for trails, places, and users. The
          numbers in the search field shows you the number of trails within the
          map section and the total number in total.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Search for places"
              src={img("/website/trailguide-iphone-search.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Search for a place by typing in the name.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Map of Nesbyen"
              src={img("/website/trailguide-iphone-search-nesbyen.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Clicking on the search result will take you to the place on the
              map.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Filter"
              src={img("/website/trailguide-iphone-filter.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Filter by difficulty, positive reviews, and positive condition
              reports.
            </div>
          </div>
        </div>
      </div>

      <div id="userpage" className={section}>
        <div className={sub_heading}>
          <Icon>
            <FaUser className="w-full h-full" />
          </Icon>
          <div>User page</div>
        </div>
        <p className={paragraph}>
          See all the activities, reviews, and condition reports added by a
          single user on Trailguide.
        </p>

        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Open the user page"
              src={img("/website/trailguide-iphone-userpage.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Click on the user image in the content page to access the user
              page.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="All the trails added by a user"
              src={img("/website/trailguide-iphone-userpage-trails.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              See all the trails and tours added by one user
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Reviews by a user"
              src={img("/website/trailguide-iphone-userpage-reviews.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              See all the reviews and condition reports by a user.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Icon(props) {
  return (
    <div className="w-8 h-8 p-1 sm:w-10 sm:h-10 sm:p-2 mr-4 bg-blue-500 text-blue-100 rounded-full">
      {props.children}
    </div>
  )
}
